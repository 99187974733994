import React,{useEffect} from 'react'
import "./Contact.scss"
import {NavLink} from "react-router-dom";


export const ThankYou = () => {


  function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}
  return (
    <section className='contactVrapper2'>
    <ScrollToTopOnMount/>
         
          <div className="containerThankYou">
   <h2>THANK YOU <br/><span>for submitting!</span></h2>
   <hr/>
  
<p>Your message has been sent!<br/>
We will come back to you within 24 hours.</p>

<NavLink to="/"> 
<button className='bootonBaner'>
          Go Home</button>
          </NavLink>
   
</div>


    </section>
  )
}
