import React from 'react';
import './NavBar.scss'
import {MobileHeader} from "../NavBar/Mobail/MobileHeader"
import {NavLink} from "react-router-dom";
import Logo from '../../asset/Logo.png';
import Flag from '../../asset/flag-switzerland.png'


export const NavBar = () => {
 
    return (
      <>
      
       <div className='navbar'>
             <div className='navLogo'>
                   <NavLink to="/"> <img src={Logo} alt="Logo" /></NavLink>
                </div>
              <div className='containerNav'>
                
                <div className='decstop1'>
                <NavLink to="/">HOME</NavLink>
          <NavLink to="/services">SERVICES</NavLink>
          <NavLink to="/aboutas">ABOUT US</NavLink>
          <NavLink to="/contact">CONTACT</NavLink>
            </div>
            


                <div className='navLok'>
                  <img src={Flag} alt='swiz'/> <p>Zurich</p>
                </div>
 </div>
  </div>
         
    <div className='mobail'>
    <div className='navLogo'>
                   <NavLink to="/"> <img src={Logo} alt="Logo" /></NavLink>
                </div>
  <MobileHeader/>

</div>
      </>
         
         
    
          
    
          
          
       

)


}