import React, {useState} from 'react';
import '../NavBar.scss'

import {AiOutlineMenu} from "react-icons/ai";
import {VscChromeClose} from "react-icons/vsc";
import {NavBarMobail} from '../Mobail/NavBarMobail';


export const MobileHeader = () => {
    const [open, setOpen] = useState(false)


    const hamburgerIcon = <VscChromeClose size={'40px'} color='#A4C5FF'
                                         className={'mobile-menu-icon_close'}
    onClick={() => setOpen((!open))}/>

    const closeIcon = <AiOutlineMenu onClick={() => setOpen((!open))}/>


    
    const closeMobileMenu = () => setOpen((open) => !open);
    return (
        <div className={'display_mobile'}>
                {open ?  hamburgerIcon : closeIcon}
                {open &&  <NavBarMobail closeMobileMenu={closeMobileMenu}/>}
           
    
    
            </div>
    
    
    
    );
}

