import React, {useEffect}from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import { NavLink } from 'react-router-dom'
import './HomePage.scss';
import videoMauntin from "../../asset/video/videoMauntin.mp4"


export const Video= () => {

    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 1300,
        });
        AOS.refresh();
    }, []);
  
  
return (
<>
 <div className='main'>
      
      
<video src={videoMauntin} autoPlay loop muted/>
    

        <div className='banerText'>
        
    <h1> Web Design and development studio from <span>Switzerland</span>. Creating responsive user friendly websites,
    that captivate and convert, empowering your online presence.</h1>
<hr/>
    <h2>GOOD DESIGN FOR GOOD PEOPLE</h2>
    <p> Bring Swiss quality and expertise to your digital product development. </p>

    <NavLink to="/contact">  
<button className='bootonBaner'>LEAVE REQUEST</button>

</NavLink>
   </div>
    </div>

</>
   )

}