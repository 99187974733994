import React from 'react';
import '../NavBar.scss'
import {AiFillLinkedin} from "react-icons/ai";
import {RiInstagramFill} from "react-icons/ri";
import Flag from '../../../asset/flag-switzerland.png'
import {NavLink} from "react-router-dom";


export const NavBarMobail = ({closeMobileMenu}) => {



    return (
   
        <div className='mobile_navlinc'>
             
  
        <div className='mobail'>
          <NavLink to="/" onClick={closeMobileMenu}>HOME</NavLink>
          <NavLink to="/services" onClick={closeMobileMenu}>SERVICES</NavLink>
          <NavLink to="/aboutas" onClick={closeMobileMenu}>ABOUT US</NavLink>
          <NavLink to="/contact" onClick={closeMobileMenu}>CONTACT</NavLink>
         
<hr/>
              <div className='cartaSoc'>
       
       <a hrefLang="chart" rel="opener" href="https://instagram.com/zak_tech_webagency?igshid=NTc4MTIwNjQ2YQ==" ><p><RiInstagramFill className='svgSocial1'/></p></a>
       <a hrefLang="chart" rel="opener" href="https://www.linkedin.com/company/zaktech-ch/" > <p><AiFillLinkedin className='svgSocial1'/></p></a>
       </div>

       <div className='navLok'>
                  <img src={Flag} alt='swiz'/> <p>Zurich</p>
                </div>
        </div>
       
   

      



       
</div>
   
         
    
          
    
          
          
       

)


}