import React from 'react';
import { NavLink } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import '../../Pages/Cookie/Cookie.scss'; 


 export const CookieBanner = () => {

    


    return (
        <>
                <CookieConsent 
               
                 location="bottom"
                 buttonText="GOT IT"
                 cookieName="myAwesomeCookieName3"
                 style={{ background:  "#1A1A20", height: "auto", fontSize: "16px", lineHeight: "22px", color:" #FFFFFF",
                 padding: "3% 8% 3% 8%",  display: "flex",width: "100%",alignItems: "center", fontFamily: "Exo"}}
                 buttonStyle={{ color: "#09090D",background:  "#A4C5FF", fontSize: "14px", border: "2px solid #A4C5FF",
                 borderRadius: "25px", width:"122px", height:"37px",alignItems: "center",  fontWeight: "600", fontFamily: "Exo"}}
                 expires={250}> 

                 <p>
                 We use cookies to personalize our service,
                  to improve your experience on the website and its subdomains.
                   <br/>We also use this information for analytics<NavLink to="/cookiePolicy" ><span style={{color: "#A4C5FF"}}> MORE INFO </span></NavLink>  
                   
                    </p>
                 

                 
            
                   
                </CookieConsent>
        </>
    );
};

