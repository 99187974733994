import React, {useEffect} from 'react';
import './Cookie.scss'  





export const CookiePolicy = () => {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
   
        return null;
    }
   
  
    return (
    
         <>
          <div className='GeneralConditionsVrapper'>
             <div className='GeneralConditions'>
            <h1>
            Cookie policy statement
            </h1>
            <h3>
           1. INTRODUCTION
            </h3>
            <p>ZAK TECH in its
            web design and development studio as data controller (“Data Controller”) hereby publishes the following 
                 Cookie Policy for the use of cookies on or through the website accessible at the
                  <a href="https://zaktech.ch"> www.zaktech.ch (“Site”)</a>. Reference should be made to the privacy policy 
                  statement for all issues not covered by the Cookie Policy. By using the site, 
                  users agree to the installation of the “first-party” or “third-party” cookies 
                  listed
                 below on their devices. If all or some cookies are disabled, some of the site’s 
                 functions might not operate correctly.</p>


           <h3>

        2. DEFINITION
        </h3>    
        <ScrollToTopOnMount/>   
<h4>
The Site enables the User to:
</h4>
    

<p><span>User or Navigator:</span> anyone navigating the Site and able to use the services offered by the Data Controller.<br/>
<span>Cookies:</span> a cookie is a file consisting of a single line of text, which can be saved temporarily in the memory of 
the User’s computer (“session” or “temporary” cookie) or placed on its hard drive via the server of a web page
 (“persistent” or “permanent” cookie).<br/>
<span>First-party or proprietary cookies:</span> files installed on the user’s device directly by the website he is visiting.<br/>
<span>Third-party cookies:</span> files installed by external sites, by means of code incorporated in the web page of the site
 being visited. Third-party cookies also include those installed by social media plugins (for the sharing of contents)
  or cookies which analyze use of the website.<br/>
<span>Technical cookies:</span> these are necessary for the correct functioning of the site, such as for tracking a user’s login,
 and do not collect any personal information.<br/>
<span>Function cookies:</span> these are basically technical cookies used to save selections made (such as the language) or 
suggest texts in forms to be filled in. This type of cookie may involve personal information, but without them the 
site’s functions may not work correctly.<br/>
<span>Performance cookies:</span> these are basically technical cookies, used for collecting information which relates not to 
the user as a person but to their navigating behavior, in order to improve the site’s performance. Data are collected 
in aggregated, anonymous form by automatic analysis tools. In general, their deactivation does not impair the site’s
 operation.<br/>
<span>Social media cookies:</span> the use of the functions of or connections to some social networks (such as Facebook, Twitter,
 YouTube, Google Maps, etc.) implies the installation of (third-party) cookies. Their deactivation does not impair 
 the site’s operation, but it may render the relative functions or links unusable.<br/>
<span>Profiling cookies:</span> they are installed to collect information about the user’s preferences, to allow the display
 of relevant contents. They may save data concerning navigation on other websites. In general, they acquire the user’s IP
  address and information which may relate to his person and preferences.<br></br>
<span>Google Analytics:</span> a web analysis services supplied by Google Inc. (“Google”), this service collects data in anonymous form to allow the site’s performance to be improved.</p>


<h3>3. PROCESSING PROCEDURES</h3>

<p>
3.1. Introduction
This Site does not use first-party persistent and/or
 first-party profiling cookies, but only technical cookies 
 and third-party cookies as described below.
</p>
<p>
3.2. Use of first-party cookiesThe Site only uses technical first-party
 cookies, necessary for the platform and its extensions to maintain the site’s basic functions.
</p>

<p>
3.3. Use of third-party cookiesThe Site uses the Google Analytics service, which uses
 cookies needed for its operation; it also uses 
additional third-party cookies – please refer to the list provided in the next point.
</p>

<p>
3.4. Cookie ZAK TECH – __utma – third-party profiling – more than one week
 Google Analytics.
 zaktechconnect@gmail.com – __utmb – third-party profiling – up to one day –
Google Analytics.
zaktechconnect@gmail.com – __utmc – third-party profiling – current session –
 Google Analytics.
 zaktechconnect@gmail.com – __utmz – third-party profiling – more than one week – 
 Google Analytics.
 zaktechconnect@gmail.com – __utmt – third-party profiling – up to one day – 
  Google Analytics.
</p>
<h3>4. PURPOSE OF THE USE OF FIRST-PARTY COOKIES</h3>

<p>
Technical Cookies may be session cookies, used to allow efficient surfing of the site,
 to establish the navigation session and to manage display of the cookie policy statement 
 and the User’s relative choice. The Data Controller uses the data generated by these Cookies
  as an aid to in-house analysis and monitoring of the traffic generated by Users 
  (accesses and pages visited, in particular)
 and to allow improvements to be made in terms of operation and navigation.
</p>

<h3>5. USE OF FIRST-PARTY COOKIESS</h3>

<p>Session cookies are strictly limited to the transmission of session identification data,
     consisting of random numbers generated by the server, which do not allow any personal
      identification of individual Users. The following data are processed in anonymous form,
       without the direct acquisition of personal data which identify the User via Google Analytics
        Cookies: IP address (which also allows geo-localisation); time and origin of visits; 
        any previous visits by the same user; 
    and other parameters relating to the operating system, IT environment and devices used.</p>


<h3>6. DURATION OF FIRST-PARTY COOKIES AND THE DATA GENERATED BY GOOGLE ANALYTICS</h3>

<p>Session cookies are not permanently saved on the User’s computer and disappear when the browser is
     shut down. Google stores the data generated by
     Google Analytics as specified in the cookie policy statement available at:
   Cookie Usage  </p>


<h3>7.RESPONSIBILITY</h3>

<p>The Data Controller undertakes to use the data generated by Cookies solely
     for the purposes set out above. The Data Controller is not responsible for
      data processing by Google using the system described above, and merely notes 
      the assurances provided by the latter, which complies with the data protection 
      provisions of the “Privacy Shield” agreement signed by the European Commission
       and the United States Department of Commerce. The data generated by cookies about 
       use of the Website by the User (including the IP address) are transmitted to and 
       stored on the Google servers. Google uses these data to analyze Site navigation 
       behaviors and provide the Data Controller with reports about visits to the Site.
        Google may also transfer this information to third parties if required by law or 
        f these third parties handle the aforementioned information on behalf of Google. 
        Google’s Privacy Policy Statement regarding the Google Analytics service is available 
        for consultation at www.google.com/analytics/.
         Google’s Privacy Policy
         is available at www.google.com/intl/en/policies/privacy/.
         Subject to the obligations on 
         the Data Controller under national law, Google, in its capacity as independent data controller,
     has sole responsibility (under civil and criminal law) for the processing of data by means of the aforesaid system.</p>

<h3>8. CONSEQUENCES OF REFUSAL OF CONSENT TO PROCESSING</h3>


<p>As explained on the Google website at the link
   www.support.google.com/analytics/
    in order to refuse his consent to the use of Cookies by Google Analytics, the User must modify his browser 
    settings by downloading the additional component for deactivating Google Analytics JavaScript (ga.js, analytics.js, dc.js), 
    available at www.tools.google.com/dlpage.Disabling Google Analytics Cookies does not in any way
     restrict use of the Site, but it does obstruct the Data Controller in its pursuance of the purpose set out above.</p>

      <h3>9. MANAGING AND ERASING COOKIES</h3>

<p>
Users can choose to disable the use of all or some cookies by modifying the settings of their browsers so that they
 block cookies or alert the users before installing them. The relative instructions are available at:<br/>
Chromium:www.chromium.org<br/>
Tor:www.torproject.org<br/>
Chrome:www.support.google.com/
Explorer:www.support.microsoft.com/en-en/help/17442/windows-internet-explorer-delete-manage-cookies
#ie=ie-11Opera: www.help.opera.com/Windows/
Safari:www.support.apple.com/en-en/HT201265<br/>
Srware: www.srware.net.</p>
     
     <p>
     For further information about opting out from the installation of third-party cookies, visit www.youronlinechoices.com.

     </p>
        </div>
         
       
 </div>
         </>
    )
}