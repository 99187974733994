import React, {useEffect} from 'react';
import './HomePage.scss';
import {IoLogoFigma} from 'react-icons/io5';
import {SiAdobelightroom} from 'react-icons/si';
import {SiAdobeillustrator} from 'react-icons/si';
import {BsBootstrap} from 'react-icons/bs';
import {FaReact} from 'react-icons/fa';
import {SiRedux} from 'react-icons/si';
import {FaSass} from 'react-icons/fa'
import AOS from 'aos';
import "aos/dist/aos.css";
import {SiWordpress} from'react-icons/si'
import {Accordion} from '../../Components/ColapsServis/Accordion'
import  {Video} from './Video';
import { NavLink } from 'react-router-dom'


export const HomePage = () => {


   useEffect(() => {
      AOS.init({
          duration: 2000,
          disable: window.innerWidth < 1300,
      });
      AOS.refresh();
  }, []);


  function ScrollToTopOnMount() {
     useEffect(() => {
         window.scrollTo(0, 0);
     }, []);

     return null;
 }

   return (
      <>

         <section>
           <Video/>
      
          </section>

 <section className='aboutOur'>
 <ScrollToTopOnMount/>
 
             <div className='blocRaitHome'>
  <div className='textServices'>
               <h3 data-aos="fade-left">
               Beautiful<span> designs</span>. Engaging user <span>experience.<br/> Fully responsive.</span>
            </h3>

              <p>We specialise in creating unique and responsive designs that are tailored to 
                    tell your brand's 
                    story. Our team works closely with you to ensure that the design accurately
                     reflects your values and goals. </p>
            </div>

           <div className='whatWeDo'>
           
<div className='whatWeDoRait'>
            
<div className='svgWhatWeDo'>
<div className="circle"
data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"><span style={{
          paddingRight: '11px' }}>01</span></div>
     <p>Expertise: Unleash our web studio's seasoned proficiency.</p>
   </div>


<div className='svgWhatWeDo'>

<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"className="circle"><span>02</span></div>
  <p>Creativity: Experience captivating, innovative web solutions.</p>
      </div>   
               
  
  
<div className='svgWhatWeDo'>
  <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"className="circle"><span>03</span></div>
    <p>Customization: Tailored services to meet your unique needs.</p>
</div> 

<div className='svgWhatWeDo'>
  <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000"className="circle"><span>04</span></div>
    <p>Collaboration: Transparent communication for good results.</p>
</div> 

 <NavLink to="/services">
<button className='bootonBaner'>LEARN MORE</button>
               </NavLink>

          </div>
       </div>
     </div>

</section>



<section className='whoWeAre'>
  
</section>





<section className='weWorkFor'>
        <div className='weWorkFortext'>
               <h3> Tools <span>we use</span></h3>
            </div>

          <div className='toolsVrapper'>
            <div data-aos="flip-down" className='tools'>
            <IoLogoFigma/>
            <SiAdobelightroom/>
            <SiAdobeillustrator/>
            <BsBootstrap/>
            <FaReact/>
            <FaSass/>
            <SiRedux/>
            <SiWordpress/>
             </div>
</div>
            </section>


<section className='bannerMauntins'>

<div className='mauitin'>
   
<h5 data-aos="zoom-in">
“Your success is our mission. Let us help you achieve it through innovative digitalsolutions <br/> and cutting-edge technology." 
</h5>
<div className='pZak'>
<p data-aos="fade-left">
 ZAK TECH    
  </p>

</div>
</div>
</section>
   

 <section className='homeCartsBootom'>
<div data-aos="flip-down" className='webDevelopment'>
     <h4>Web Design</h4>
     <h4>UX&UI Design</h4>
     <h4>Web Development</h4>
</div>

<div className='mobailHr'>
  <hr />   
</div>

         <div className='process'>
        <h3 data-aos="fade-left">Embracing <span>every project</span>  with unwavering <span>passion</span> and commitment, treating each one as a <span>work</span> of art.</h3>
            <p data-aos="flip-down">We envision your website as more than just another template. It should be a catalyst for
                conversions, sales, and a memorable online presence that not only works seamlessly
                but also enhances your and our reputation. Take the first step by consulting with us
                 for expert guidance.</p>
           

                 <NavLink to="/contact">
<button data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500"className='bootonBaner'>COOPERATE</button></NavLink>
 </div>
  
</section>


<section className='whyZAKTECH'>
     <div className='wrapperBlokWhyZakTech'>
<div className='mauntinLeft'>
<h2 data-aos="flip-left">
Elevate Your Business: Conquer the Digital Peaks.
</h2>
<p>
By connecting ourselves with mountains, we aim to reflect these qualities in our work, providing
 exceptional web solutions that empower businesses to conquer their digital goals and 
 rise above the competition.
</p>
</div>

     </div>

     
<div className='WhyZakVrapper'>

<h2 data-aos="flip-right">
     <span>5 reasons</span> to cooperate with<br/> 
ZAK TECK studio 
</h2>
<hr  data-aos="fade-right"/>
   <h3 data-aos="fade-up"
     data-aos-duration="3000">
1. MODERN & SOLUTIONS 
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
Our IT agency prides itself on its ability to provide modern and
 creative solutions that meet the unique needs of our clients. We are 
 committed to staying up-to-date
 with the latest technologies and trends to ensure that our clients 
 receive the most innovative and effective solutions possible.
</p>
</div>
</section>



<section className='whyZAKTECHBleck'>
<div className='WhyZakVrapper'>

<h3 data-aos="fade-up"
     data-aos-duration="3000">2. FAST SERVICE
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
We understand that time is of the essence, and we work diligently to provide our clients with fast and efficient 
service. Our team is dedicated to completing projects on time and within budget,
 without sacrificing quality or attention to detail.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
3. PROFESSIONAL EXPERTISE
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
Our team is composed of highly skilled and experienced professionals who are passionate about what they do. We take pride in our ability
 to provide our clients with the highest level of professional expertise,
  and we are committed to delivering exceptional results every time.
</p>


<h3 data-aos="fade-up"
     data-aos-duration="3000">
4. PERSONALIZED ATTENTION
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
We understand that every client is unique, and we take the time to get to know each of our clients on a personal level.
 We work closely with our clients to understand their specific needs and goals, and we tailor our services to meet those needs.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
5. COMPETITIVE PRICING
 </h3>


<p data-aos="fade-up"
     data-aos-duration="3000">
Our Web studio is committed to providing our clients with the most competitive pricing possible. We understand that 
cost is a significant factor for many clients, and we work diligently to ensure that our services are both affordable.
</p>



</div>


</section>

<section className='collaboration'>

<div className='homeBotoomVrapper'>
     <div className='process3'>
   <h2>Interested about collaboration and to create stunning websites together?</h2>
    <p>Contact in any convenient way or leave a request.</p>

     </div>




<NavLink to="/contact">
<button data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500"className='bootonBaner'><span>LEAVE REQUEST</span></button></NavLink>
</div>


     
</section>

<section className='acardionVrapper'>
      <h2>FAQ</h2>
      <Accordion/>
</section>


  </>

   );
}