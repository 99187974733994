import React, {useEffect} from 'react';
import './About.scss'
import AOS from 'aos';
import "aos/dist/aos.css";
import aboutTop from '../../asset/video/diandkris.webp'
import Kristina from '../../asset/video/34BDEA77.webp'
import Diana from '../../asset/video/A308862A.webp'

export const AboutAs = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000,
            disable: window.innerWidth < 1300,
        });
        AOS.refresh();
    }, []);

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
   
        return null;
    }
   
  
return (
<>


<section className='InnovativeVrapper'>

    <h1>
    ZAK TECH Web Development Studio
   
   
    </h1>


<div className='creativeVrapper '>
<div className='creativeLeft'>
        <p> Creative approach.</p>

         <p>Modern design.</p>
         <p>Clear navigation.</p>
         <p>Timely Launch.</p>
  </div>   

  <div className='creativeImg'>
     
<img src={aboutTop} alt='aboutTaiblt'/>

 </div>



</div>
 

      
  
   

    
    </section>




<section className='whoWeAreVrapper'>
    
<hr/>
<div className='womenWrapper'>
     <div className='whoWeAreWomen'>

<ScrollToTopOnMount/>

<h2 data-aos="fade-right">
What does our<br/> studio do?

</h2>
<p>We are web development studio based in Zurich, Switzerland. Our web studio specializes in creating
      innovative and visually stunning websites that engage and captivate users, delivering exceptional 
      digital experiences for our clients.
      </p>

<img src={Kristina} alt='kristina'/>

<p>ZAK TECH - is a creative web studio specialising in the development of websites for small and mid-sized companies. </p>
<p>Nestled in the heart of Switzerland, our web studio draws inspiration from the majestic Alps that surround us. 
     Our concept harmoniously weaves together the natural beauty of the mountains with cutting-edge technology,
      resulting in web designs that are both visually striking and functionally robust. Just as the Alps stand 
      tall and resilient, our studio is committed to delivering high-quality solutions that endure the test of 
      time, leaving a lasting impact on our clients' online presence. We create digital products that are both
      visually stunning and technically flawless. Our passion for technology and desire to make people happy 
      drive our commitment to excellence.</p>
</div>

<div className='whoWeAreWomenRait'>
     <img src={Diana} alt='Diana'/>



</div>
</div>


</section>

<section className='compAboutAs'>
     <h2>
     Transforming Ideas into Digital<br/> Masterpieces 
     </h2>

</section>


<section className='reliableWrapper'>


<div className='reliableWrapperLeft'>

<h2>Reliable.</h2>
<p>Clients can rely on us to consistently meet their expectations 
     and deliver on our promises. We value trust and strive to 
     establish long-term relationships built on reliability and dependability.</p>

<hr/>


<h2>Modern.</h2>
<p>We stay abreast of the latest trends and technologies in the digital landscape, 
     ensuring that our solutions are modern and aligned with 
     current industry standards. We strive to create forward-thinking designs and 
     implement cutting-edge functionalities.</p>

<hr/>

</div>



<div className='reliableWrapperRait'>
<h2>Efficiency.</h2>
<p>We optimize our processes and workflows to ensure efficient project execution and timely delivery. 
     Our focus on efficiency enables us to maximize productivity without compromising the quality of our work</p>

<hr/>


<h2>Progressive.</h2>
<p>Our approach is progressive, embracing innovation and pushing the boundaries of what's possible in web 
     design and development. We continuously seek new ideas and
      techniques to enhance our solutions and provide clients with the most innovative and impactful digital experiences.</p>

<hr/>
</div>
</section>


    <section className='whyZAKTECH2'>

<div className='WhyZakVrapper32'>

<h2>Our Values</h2>

   <h3 data-aos="fade-up"
     data-aos-duration="3000">
INNOVATION
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
 As a web agency, we strive to be innovative, constantly pushing the boundaries of what's possible to create unique and effective digital solutions. 
 We embrace creativity and are committed to staying at the forefront of industry trends and technologies. 
</p>
<h3 data-aos="fade-up"
     data-aos-duration="3000">
CUSTOMER SERVICE
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
 Providing excellent customer service is our top priority. We understand that building lasting relationships with our clients is crucial, and that's why we go above and beyond to meet their needs. We value open communication, 
 actively listen to our clients' requirements, and work tirelessly to exceed their expectations. 
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
COLLABORATION
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
We believe that effective collaboration is key to achieving successful outcomes. We foster a culture of teamwork, trust, and open communication both within our team and with our clients. We actively involve our clients in the creative process, 
ensuring that their insights and ideas are valued and incorporated into our work.
</p>


<h3 data-aos="fade-up"
     data-aos-duration="3000">
QUALITY
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
Delivering high-quality work is our commitment to our clients. We understand that our reputation relies on the excellence of our output, and that's why we prioritize quality at every stage of the development process. 
Our team is dedicated to thorough quality assurance practices to ensure that our deliverables meet the highest standards.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
ADAPTABILITY
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
The digital landscape is constantly evolving, and we embrace the need for adaptability. We understand that staying ahead requires us to be flexible and responsive to changing technologies and trends. We are always ready to pivot and adjust 
our strategies to overcome new challenges and provide the best possible solutions for our clients.
</p>
</div>
 

</section>

</>
)



}