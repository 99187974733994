import {createBrowserRouter} from "react-router-dom";
import { HomePage } from "../Pages/HomePage/HomePage";
import { AboutAs} from "../Pages/AboutAs/AboutAs";
import { Services } from "../Pages/Services/Services";
import { Contact } from "../Pages/Contact/Contact";
import NotFound from "../Pages/NotFound"
import { LayoutPublic } from "../layout/LayoutPublic";
import {CookiePolicy} from "../Pages/Cookie/Cookie"
import {PrivacyPolicy} from "../Pages/Cookie/PrivacyPolicy"
import {GeneralConditions} from "../Pages/Cookie/GeneraiCondition"
import {ThankYou} from "../Pages/Contact/ThankYou"


export const router = createBrowserRouter([

{
path: '/',
element: <LayoutPublic />,
errorElement: <NotFound />,
children: [
{
      path: "/",
      element:<HomePage />,
     
    },
    {
      path: "/services",
      element: <Services />,
    },
   
    {
      path: "/aboutas",
      element: <AboutAs />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/generalConditions",
      element: <GeneralConditions />,
    }, {
      path: "/privacyPolicy",
      element: <PrivacyPolicy />,
    }, {
      path: "/cookiePolicy",
      element: <CookiePolicy />,
    },
    {
      path: "/contact/thankyou",
      element: <ThankYou/>,
    },
]

},


    
  ]);