import React, {useEffect} from 'react';
import './Cookie.scss'  





export const PrivacyPolicy = () => {
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
   
        return null;
    }
   
  
    return (
    
         <>
          <div className='GeneralConditionsVrapper'>
             <div className='GeneralConditions'>
            <h1>
            Privacy Policy Statement under art. 13 of Regulation
             2016/679/EU (GDPR) and Federal Act on Data Protection (FADP) of 19 June 1992
            </h1>
            
            <p>The GDPR and the FADP protect the fundamental rights and freedoms of natural persons, 
                in particular their right to the protection of personal data. ZAK TECH believes
                that the right to privacy must be protected with the greatest possible care and is
                 therefore committed to the effective implementation of the aims set by the relevant 
                 European legislation.When collecting, processing and storing the data of the persons
                  who contact ZAK TECH, all reasonable measures are taken to ensure that the activities
                   are fair, lawful and transparent and that no data that is not really required is
                    processed or saved. ZAK TECH processes data using IT and electronic tools and 
                    tore data on paper, electronic data carriers and other useful media. All tools 
                    and media used are protected by technical and organizational measures to guarantee 
                    appropriate personal data security. The following information is therefore provided 
                    in compliance with art. 13 of the GDPR and with FADP:</p>


           <h3>

1. PURPOSE
        </h3>    
        <ScrollToTopOnMount/>   

    

<p>The data you provide will be processed, also using computerized tools,
    to enable you to use the services offered by ZAK TECH, whereby 
    technical and organizational measures are taken to ensure an adequate 
    security of personal data.</p>


<h3>2. NATURE OF INFORMATION</h3>

<p>
The contribution of personal data is compulsory for registration on the site and use of the services offered.
</p>


<h3>3. USE OF DATA</h3>

<p>
We use your data to guarantee you access to our services, the company-specific surveys and evaluations, as well 
as for administrative, financial and/or accounting purposes. Such processing of data is necessary for the proper
 provision of our services to the persons who register for the purpose of using them 
nd is based on the Data Controller’s legitimate interest (point 4). Users are entitled to object at any time..
</p>

<h3>4. DATA CONTROLLER</h3>

<p>The data controller is ZAK TECH ы
     Contact details: zaktechconnect@gmail.com, +41 44 585 11 88.</p>


<h3>5. DISCLOSURE OF DATA</h3>

<p>Your data may be disclosed to third parties specifically appointed and designated 
    as Data Processors. They will only receive the data necessary for 
    purposes related to the provision of the services of ZAK TECH and the management of
     the operational business.</p>


<h3>6. AUTHORIZED ENTITIES</h3>

<p>All personnel of ZAK TECH fulfil their duties in accordance with instructions received from 
    the Data Controller within the meaning of art. 29 of the GDPR and 
    have been authorized to process the data.</p>

<h3>7. RIGHTS</h3>


<p>In accordance with the data protection laws, you are entitled to request an excerpt of whether or 
    not personal data concerning you has been processed and whether your personal data is being accessed.You
     have the right to ask for a rectification or erasure of the data and you can request confirmation of the
      rectifications and erasure of the data at all places where Zak Tech AG has transmitted the data.If you
       believe that the processing of your data violates the provisions of the GDPR, you are entitled to lodge
        a complaint with the Supervisory Authority in accordance with art. 77 of the GDPR. If you wish to 
        request further information on the processing of your personal data or to exercise your rights, 
    please contact ZAK TECH in writing or send an e-mail to zaktechconnect@gmail.com.</p>

      <h3>8. DATA STORAGE</h3>

<p>
Data are stored for as long as necessary for the provision of our
 services. Personal data will be stored in electronic/IT form for 
 the time strictly necessary to fulfil the purposes stated in point 
 1, in compliance with your right to privacy and the relevant legislation.
  For purposes of analyses for the development and improvement of the service,
   the user’s personal data may be stored for an additional period of 36 months. 
   In the event of the exercise of your right to be forgotten by requesting the 
   specific erasure of the personal data processed by the controller, please remember
    that these data will be stored in a protected form and with restricted access, 
    solely for the purposes of the investigation and prosecution of offenses, for a
     period not exceeding 12 months from the date of the request, after which they 
     will be securely erased or irreversible anonymized. To conclude, you are reminded 
     that for the same purposes, the data relating to data traffic, not including the 
     contents of any communications, will be stored for a period of no
 more than 6 years, pursuant to art. 24 of Law no. 167/2017, implementing Directive (EU) 2017/541 on combating terrorism.</p>
     
     
        </div>
         </div>
       
 
         </>
    )
}