export const data = [
    {
      question: "What kind of services does your web studio offer?",
     
      answer:
        "Our web agency offers a wide range of services, including website design and development, website redesign, search engine optimization (SEO), content creation.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "How much does it cost to build a website?",
     
      answer:
        "To determine the cost of a website, we need specific details such as the number of pages (1-20) and the design type (static or animated). A technical specification or an estimate of the page count is essential for an accurate cost assessment. A professional website development agency is not limited to one design. Design studios have a cycle of tasks and a built-in system. This system allows them to create unique projects at a decent level and provides not just a website but a whole business analysis. You can ask for the price in our web studio by phone, email, or simply send us a request. We will be happy to help and answer all your questions. ",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "How long does it take to build a website?",
   
      answer:
        "The time it takes to build a website depends on its complexity and the scope of the project. A simple website can be completed in a few weeks, while more complex projects may take several months. We work closely with our clients to establish realistic timelines and keep them informed throughout the process.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "What kind of programs do you use to create websites?",
      
      answer:
        "At our web studio, we utilise a combination of WordPress and hand-written code to create websites. WordPress -  is a popular content management system (CMS) that provides a user-friendly interface for managing website content. Hand-written code is the process of manually crafting programming code from scratch, allowing for highly customised and unique website development. Custom code needs to tailor websites to specific client needs, ensuring unique and optimised solutions. We leverage the strengths of both WordPress and hand-written code to deliver functional and visually appealing websites.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "Do you provide hosting and domain registration services?",
     
      answer:
        "Yes, we can assist you with domain registration and recommend hosting providers based on your specific requirements. We can also manage hosting and domain-related tasks for you, ensuring a smooth setup and ongoing support.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "Will my website be mobile-friendly and responsive?",
  
      answer:
        "Absolutely! We prioritize responsive web design, ensuring that your website looks great and functions properly on all devices, including smartphones, tablets, and desktop computers.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "Do you offer support after the website is launched?",
   
      answer:
        "Yes, we offer post-launch support to address any issues or questions you may have. Our team is committed to ensuring your satisfaction and providing ongoing assistance whenever needed.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "Will my website be mobile-friendly and responsive?",
     
      answer:
        "Absolutely! We prioritize responsive web design, ensuring that your website looks great and functions properly on all devices, including smartphones, tablets, and desktop computers.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    },
    {
      question: "How to order a site and what is required",
   
      answer:
        "Take a moment to assess your competitors and carefully consider the purpose of your website. Think about the desired functionality, including forms, design elements, and more. Capture these essential details in a notebook, as each piece of information holds value. When you're ready, reach out to us, and we'll gladly discuss the specifics with you. We're here to ensure your website meets your needs and exceeds your expectations.",
        dpopdownP:"dpopdownP",
        wrapDiv: "wrapDiv",
        wrapH3:"wrapH3",
    }
  ];