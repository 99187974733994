import React, {useEffect, useRef} from 'react';
import './Contact.scss'
import emailjs from "@emailjs/browser";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router";


export const Contact = () => {


 const nav = useNavigate();

 function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
   
        return null;
    }
    const form = useRef();
    function sendEmail(data, e) {
      e.preventDefault();
      emailjs
          .sendForm('service_s3v5tpn', 'template_3gr6q7b', e.target, 'cV2G7qCmh1NDLn3V6')



          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      e.target.reset()

     nav("thankyou")


      reset()
  }
  const {handleSubmit, 
    register,reset} = useForm({
    mode: "onBlur",
     defaultValues: {
        IamInterestIn:"",
        Name: "",
        Email: "",
        Telephone: "",
        Organization:"",
        Message: "",
        

    }
  
});





return (
    <>
    <section className='contactVrapper'>
    <ScrollToTopOnMount/>
         
          <div className="container">
   <h1><span> Let’s WORK</span> <br/>
   TOGETHER </h1>
  



  <form className="contact_form" ref={form} onSubmit={handleSubmit(sendEmail)}>
  <select  name="IamInterestIn" {...register("I am interest in")}>
  <option>I'd like to start a project</option>
  <option>I'd like to ask a question</option>
 <option >I'd like to make a proposal</option>
</select>
    
    <div className="name">
      
      <input type="text" placeholder="Name*"name="Name" id="name_input" required></input>
    </div>
    <div className="email">
      
      <input type="email" placeholder="Email*" name="Email" id="email_input" required></input>
    </div>
    <div className="name">
     
      <input type="text" placeholder="Phone*" name="Telephone" id="telephone_input" required></input>
    </div>
    <div className="organization">
      
      <input type="text" placeholder="Organization" name="Organization" id="name_input" required></input>
    </div>
      <div className="subject">
   
      <div className="message">
      
      <textarea name="Message" placeholder="Message" id="message_input" cols="30" rows="5" required></textarea>
    </div>
    
    
      </div>
      <div className='submitBooton'>
     <button type="submit" className='bootonBaner'>Send</button>

   
    
      </div>
   
     
    </form>
   
</div>


    </section>
  
    </>


)


}