import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {CookiesProvider} from "react-cookie";
import {router} from './Router/Router.jsx'
import {RouterProvider} from "react-router-dom";




 


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
 
  <React.StrictMode>
 
<CookiesProvider>
<RouterProvider router={router} />
</CookiesProvider>


  </React.StrictMode>
  



);


