import { Outlet } from "react-router"
import { NavBar } from "../Components/NavBar/NavBar"
import {Futer} from "../Components/Foter/Futer"
import Scrolling from "../Components/Scrolling/Scrolling"
import {CookieBanner} from "../Components/CookieBanner/CookieBanner"
import {useState, useEffect} from "react"
import RiseLoader from "react-spinners/RiseLoader";



const override = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height:"100vh"
   
  };

export const LayoutPublic = () => {
const [loading , setLouding] =useState(false)
useEffect(()=>{
setLouding(true)
setTimeout(()=>{
    setLouding(false)

},3000)
},[])




    return (
        <>
{
    loading? 
    <RiseLoader
    color={'#A4C5FF'}
    loading={loading}
    cssOverride={override}
    size={20}
    aria-label="Loading Spinner"
    data-testid="loader"
   
  />
  : 
 <>

 <NavBar />
       
           <main>
               <Outlet />
               <Scrolling/>
            
               <CookieBanner/>
            
             
                   
               </main>
                
           <Futer/>
</>
}

      
       </>

)
}