import React, { useState }from "react";
import {useEffect} from "react";
import {data} from"./data";
import styled from "styled-components";
import {BiChevronRight, BiChevronDown} from "react-icons/bi"
import { IconContext } from "react-icons";
import AOS from 'aos';
import "aos/dist/aos.css";
import '../../Pages/Services/Services.scss'

const AccordionSection = styled.div`
display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
 
  
    
`;
const Container = styled.div`
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;

`
const Wrap = styled.div`


svg{
  font-size: 25px;
}

`;

const Dropdown = styled.div``;


export const Accordion = () => {

 
  useEffect(() => {
    AOS.init({
        duration: 2000,
        disable: window.innerWidth < 1300,
    });
    AOS.refresh();
}, []);





const [clicked, setCliked] = useState(false)

const toggle = index => {
  if(clicked === index) {

    return setCliked(null)
  }
  setCliked(index)
}

  return(
    <IconContext.Provider value={{ color:' #FFFFFF',
    saze: '25px'}}>
      <AccordionSection>
        <Container>
         {data.map((item, index) => {
          return (
            <>
            <Wrap className={item.wrapDiv} key={index} onClick={() => toggle(index)}>
            
               <h3 className={item.wrapH3} data-aos="flip-up">
              {item.question}
            </h3>
            
            <span>{clicked === index ? <BiChevronDown /> : <BiChevronRight />}</span>
             
            </Wrap> 
     
            
            {clicked === index ? (
            <Dropdown className={item.dpopdownP}>
             <p>{item.answer}</p>
           </Dropdown>
            ) : null}
       
          


            </>

          )
         })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>


  )


};