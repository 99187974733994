import React, {useEffect} from 'react';
import './Services.scss';
import {HiArrowLongRight} from "react-icons/hi2";

import AOS from 'aos';
import "aos/dist/aos.css";

import { NavLink } from 'react-router-dom'


export const Services = () => {
  useEffect(() => {
    AOS.init({
        duration: 2000,
        disable: window.innerWidth < 1300,
    });
    AOS.refresh();
}, []);
function ScrollToTopOnMount() {
  useEffect(() => {
      window.scrollTo(0, 0);
  }, []);

  return null;
}

  
return (

<>

<section className='servisVrapper'>
  

       <div className='banerText'>
           
          <h1 data-aos="fade-right">Unlock the full potential of your business with a professionally
             designed product that captures the essence of your brand.</h1>
      <hr/>
          <h2 data-aos="fade-up-left">GOOD DESIGN FOR GOOD PEOPLE</h2>
          <p>We offer a wide range of services to help you to succeed in today's digital landscape.</p>
      
          <NavLink to="/contact">  
      <button className='bootonBaner'  data-aos="fade-right">COOPERATE</button>
      
      </NavLink>
         </div>
         

<ScrollToTopOnMount/>
  </section>

  
  
  
  

<section className='servisSolutions'> 
<div className='blocRaitHome'>
  <div className='textServices'>
               <h3 data-aos="fade-left">
               Offering a comprehensive range of <span>web design</span>  and development services  <br/> we helping <span>businesses</span> thrive in the digital landscape.
            </h3>

              <p>We specialise in creating unique and responsive designs that are tailored to 
                    tell your brand's 
                    story. Our team works closely with you to ensure that the design accurately
                     reflects your values and goals. </p>
   

                  
            </div>


            <div className='textServices'>
               <h3 className='textServicesH' data-aos="fade-left">
               Services <span>we offer</span> 
            </h3>
            <hr data-aos="fade-right"/>  
           

                 <h4>Landing page </h4>
              <p>High-Converting Landing Pages. Drive Action, Boost Conversions
              Capture attention, prompt action, and increase conversions with our expertly 
             crafted landing pages. Our targeted designs and persuasive content guide visitors towards 
            taking specific actions, delivering tangible results for your business.</p>

             <hr data-aos="fade-right"/>    
          </div>
          
            <div className='textServices'>
            
                 <h4 data-aos="fade-left">Business card site</h4>
              <p>A business card website is a professional online presence showcasing 
                essential business information. It acts as a digital business
                 card, providing key details about your business.
                  It serves as a starting point for potential customers to learn about your
                   offerings and take the next steps in engaging with your business.</p>
                   <hr  data-aos="fade-right"/>    
            </div>

            <div className='textServices'>
            
            <h4  data-aos="fade-left">Corporate website</h4>
         <p>A corporate website is an online platform that represents a company's brand, 
          showcase its products or services, and facilitates communication with stakeholders. 
          It establishes a strong online presence, promotes the company's offerings, 
          and provides essential information to visitors.</p>
              <hr  data-aos="fade-right"/>    
       </div>
       <div className='textServices'>
            
            <h4 data-aos="fade-left">Invitation website</h4>
         <p>Stylish and modern way to inform guests about the most important day in your life:
           date and place of the celebration, dress code, love story, list of invitees, program of the event.</p>
             
       </div>
</div>
</section>




<section className='WeOfferVrapper' >

<h2  data-aos="fade-up"
     data-aos-anchor-placement="bottom-bottom">Order agency services to create a website</h2>


<NavLink to="/contact">
  <HiArrowLongRight/>
</NavLink>

</section>
<section className='servisHend'>

</section>





<section className='ServisSteps'>

<div className='process'>
        <h3 data-aos="fade-left"><span>Steps</span> and algorithm for <span>creating a site </span>from scratch
        </h3>
            <p data-aos="flip-down">The stages of creating sites are very different from the type of project,
             purpose and scope of work. Therefore, we have given a basic understanding of what stages of website
              development are in our studio. We excel in developing websites on WordPress and through meticulously handcrafted coding</p>
           </div>


       
          
          <div className='StepsWrapper'>

<div className='stepLeft'>
  <div className="circle">
   <div className='nam1'><span>01</span></div> 
<p>Research and Analysis</p>

  </div>

<div className="circle">
<div className='nam1'><span>02</span></div>
<p>Contract and working conditions</p>

</div>

<div className="circle">
 
 <div className='nam1'><span>03</span></div>
<p>Wireframing and Prototyping</p>
</div>

<div className="circle">
<div className='nam1'><span>04</span></div>
<p>Visual Design
UI Design </p>

</div>
  <div className="circle">
  <div className='nam1'><span>05</span></div>
<p>Content Creation and Integration</p>

    
  </div>


</div>




  <div className='stepsRait'>
<div className="circle">
<div className='nam1'><span>06</span></div>
<p>Responsive and Mobile Optimisation</p>

</div>

    <div className="circle">
    <div className='nam1'><span>07</span></div>
<p>Development and Coding</p>

</div>


  <div className="circle"> 
  <div className='nam1'><span>08</span></div>
<p>Testing and Quality Assurance</p>

</div>
  <div className="circle">
  <div className='nam1'><span>09</span></div>
<p>Launch and Deployment</p>

  </div>

<div className="circle">
<div className='nam1'><span>10</span></div>
<p>Optimisation and Updates</p>

</div>

 </div> 

</div>
</section>



<section className='stagesServes'>
<div className='WhyZakVrapper'>
<h2 data-aos="fade-up"
     data-aos-anchor-placement="top-bottom">
Shortly about the stages of
     <span> site development </span><br/>by
ZAK TECK studio 
</h2>
<h3 data-aos="fade-up"
     data-aos-duration="3000">1. Research and analysis 
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
This stage focuses on understanding the business's objectives, target audience, 
and market landscape. Thorough research is conducted to gather insights and 
identify trends that will shape the website's design.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
2. Contract and working conditions
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
After the site development plan, project structure, and cost are approved, we proceed to finalise an agreement. This crucial stage ensures the parties' obligations are properly fulfilled. The contract delineates the terms, costs, 
responsibilities, as well as the project's development stages and the inclusion of any additional services, if required.
</p>


<h3 data-aos="fade-up"
     data-aos-duration="3000">
3. Wireframing and Prototyping
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
In this stage, the website's structure and layout are sketched out using wireframes. These serve as blueprints that outline the placement of key elements
 and ensure a seamless user experience. Prototypes are then created to visualise the interactivity and flow of the website.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
4. Visual Design and UI Design 
 </h3>


<p data-aos="fade-up"
     data-aos-duration="3000">
With a solid foundation in place, the visual design stage brings the website concept to life. This involves selecting a modern
and visually appealing aesthetic, incorporating brand elements, and creating a cohesive visual language throughout the site.
</p>


<h3 data-aos="fade-up"
     data-aos-duration="3000">5. Content Creation and Integration 
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
Compelling and engaging content is developed, including text, images, videos, and other multimedia elements. The content is
 strategically integrated into the website, aligning with the design and effectively conveying the business's messaging.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
6. Responsive and Mobile Optimisation
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
Considering the prevalence of mobile browsing, the website is designed to be responsive, adapting seamlessly to different
 screen sizes and devices. Mobile optimisation ensures optimal user experience across smartphones and tablets.
</p>


<h3 data-aos="fade-up"
     data-aos-duration="3000">
7. Development and Coding
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
The design is transformed into a functional website using coding languages and development frameworks.
 This stage involves front-end and back-end development, database integration, and the implementation of interactive features.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
8. Testing and Quality Assurance 
 </h3>


<p data-aos="fade-up"
     data-aos-duration="3000">
Rigorous testing is conducted to ensure the website functions flawlessly across various browsers, devices, 
and operating systems. Quality assurance checks are performed to identify and fix any issues or bugs.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
9. Launch and Deployment
 </h3>
<p data-aos="fade-up"
     data-aos-duration="3000">
The website is prepared for launch, with domain registration, hosting setup, and deployment to a live server. 
Proper tracking and analytics tools are implemented to measure website performance and user engagement.
</p>

<h3 data-aos="fade-up"
     data-aos-duration="3000">
10. Optimisation and Updates
 </h3>


<p data-aos="fade-up"
     data-aos-duration="3000">
After launch, the website undergoes ongoing optimisation based on user feedback, analytics data, and evolving business needs. Regular updates, 
security patches, and content enhancements are performed to keep the website modern and relevant.
</p>
</div>

</section>


  </>
)


}