import React, {useEffect} from 'react';
import './Cookie.scss'  





export const GeneralConditions = () => {
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
   
        return null;
    }
   
    return (
    
         <>
         <div className='GeneralConditionsVrapper'>
             <div className='GeneralConditions'>
            <h1>
            General Conditions of Use (GCU)
            </h1>
            <h3>
            GENERAL INFORMATION ABOUT THE WEBSITE
            </h3>
            <p>The Internet site accessible at the  <a href="https://zaktech.ch"> www.zaktech.ch</a> is
                 the property of ZAK TECH which holds all the relative rights. Use of the site
                   is permitted in order to consult the information it contains, but 
                   its use for commercial purposes, or within the context of a business, 
                   is forbidden. The term “Use” covers all operations performed by the 
                   User from the moment of access to the Site, including mere consultation, 
                   regardless of the access device (PC, tablet or smartphone), the type of
                    connection (private, third-party, public or WI-FI) and the connection
                     location. Use of the Site implies the User’s acceptance of these conditions 
                     without reservations. The conditions may be modified by ZAK TECH over time,
                      and Users
                 are therefore advised to view them periodically; the relevant version is the one 
                 published on the site at the time of use.</p>


           <h2>

        OPERATION OF THE SITE
        </h2>    
        <ScrollToTopOnMount/>   
<h4>
The Site enables the User to:
</h4>
    <ul>
        <li>
        find out about the offers and services of ZAK TECH
        </li>
        <li>
        acquire contact details and information about the business of ZAK TECH
        </li>
    </ul>

<p>This list is intended purely as a guideline and is not exhaustive.</p>


<h3>CONDITIONS OF USE OF THE SITE </h3>

<p>
Use of the Site is only permitted to people who are legally of age and
 identifiable as natural persons, or children under eighteen years of age authorized by 
 a parent or guardian. Access to some of the Site’s services may be conditional on creation 
 of an Account, for which purpose the User may be asked to provide information which allow 
 him to be identified. The User must provide truthful and complete information about his
  identity. ZAK TECH undertakes to treat credentials as well as collected and stored
   personal data that enable the user to be identified as strictly 
confidential. With regard to data processing, reference is made to the Privacy Policy Statement 
available on this website.
</p>


<h3>CODE OF CONDUCT</h3>

<h4>Users must not</h4>

<ul>
        <li>
        commit unlawful acts;
        </li>
        <li>
        extract or collect the personal data of the Site’s users by any means;
        </li>
        <li>
        extract, record or use data or contents without obtaining specific consent;
        </li>
        <li>
        store, disseminate or publish any comment or content which is unlawful,
         harmful, injurious or discriminatory, which incites hatred, which is 
         contrary to public morality, or which is 
        damaging to the privacy or rights of others, especially the image,
         reputation and intellectual property rights of ZAK TECH;
        </li>
        <li>
        engage directly or indirectly in propaganda of any kind or nature
         using material obtained from the site or with reference to it;
        </li>
        <li>
        store, disseminate or publish any contents which may be directly 
        or indirectly damaging to the rights of ZAK TECH.
        </li>



    </ul>
<h3>INTELLECTUAL PROPERTY</h3>

<p>ZAK TECH prepares the contents published on the site in full compliance
     with copyright law, in order to protect authors’ rights and conform to the 
     regulatory framework regarding intellectual property, design, brand, domain 
     names, patents and know-how. ZAK TECH is the owner of all the Contents 
     of the Site and the relative rights. 
    Users are not permitted to use the materials (texts or images) under any 
    circumstances and for any reason.</p>


<h3>RESPONSIBILITY</h3>

<p>Users use and consult the Site entirely under their own responsibility. 
ZAK TECH shall not be considered responsible for damage arising from
     access to or use of the Site and the information it contains. ZAK TECH reserves
      the right to temporarily suspend access to the Site for
      technical reasons relating to maintenance and updates, without any 
      entitlement to claim compensation on the part of users. ZAK TECH 
      shall not under any circumstances be considered responsible in the event 
      of network malfunctions preventing the correct functioning or display of 
      the Site, or which render previously published information unavailable; 
      similarly, it shall not be held responsible for any event or condition 
      relating to the user’s system (malfunctions, technical issues, etc.)
       which prevent him from making correct use of the published resources.
       ZAK TECH shall not under any circumstances be considered responsible
         in the event of the publication of
     incorrect or inaccurate information and undertakes to make the necessary 
     corrections as soon as it becomes aware of it.</p>


<h3>PERSONAL DATA PROTECTION</h3>

<p>ZAK TECH processes the personal data collected on the website in accordance 
    with the Data Protection Regulation (EU) 2016/679 of the European Parliament
     and of the Council of 27 April 2016 on the protection of natural persons 
     with regard to the processing of personal data and on the free movement of
      such data, as well as the Federal Act on Data Protection (FADP) of 19 June
       1992. Users may exercise their rights with regard to the processing of 
       their data by sending a specific request to ZAK TECH 
        <a href="https://zaktech.ch"> www.zaktech.ch</a>  The Privacy Policy Statement of ZAK TECH is available under the chapter 
    “Privacy Policy” on this website.</p>

<h3>SEVERABILITY CLAUSE</h3>


<p>In the event that one or more clauses of these General Conditions of Use 
    are declared null and void further to the application of a law, 
    a regulation or a definitive legal sentence or administrative ruling, the
     other clauses shall retain their validity.</p>

      <h3>
APPLICABLE LAW AND JURISDICTION</h3>

<p>
These General Conditions of Use are governed by Swiss law.
 The exclusive place of jurisdiction for disputes that cannot 
 be settled out of court is Zurich (Switzerland).</p>
     
        </div>
         </div>
       
 
         </>
    )
}