import React from 'react';
import './Futer.scss'
import { NavLink } from 'react-router-dom'
import {AiFillLinkedin} from "react-icons/ai";
import {RiInstagramFill} from "react-icons/ri";




export const Futer = () => {
  
return (

     <>
    

     
   <section className='footerVrapper'>





  <div className='footerDiscription'>

 <div className='services'>

        <NavLink to="/"> <h5>ZAK TECH</h5></NavLink>
         <p>What we do</p>
         <p>Our strength</p>
         <p>How it works</p>
        <p>Why ZAK TECH</p>
     </div>  


  
     
     <div className='services'>
        <NavLink to="/services"> <h5>SERVICES </h5></NavLink>
        <p>Prices</p>
        <p>Get in touch</p>
        <p>FAG</p>

     </div>  
   
   
     <div className='services'>
        <NavLink to="/aboutas"> <h5>ABOUT US</h5></NavLink>
         <p>Discover</p>
         <p>Who we are</p>
         <p>Our values</p>
        </div>  

 <div className='company'>
        <NavLink to="/contact"> <h5>Contact</h5></NavLink>
           <p>zaktechconnect@gmail.com</p>
           <p>+41 44 585 11 88</p>
          
      

    <div className='cartaSoc'>
       
    <a hrefLang="chart" rel="opener" href="https://instagram.com/zaktech_webstudio?igshid=MzRlODBiNWFlZA=="><p><RiInstagramFill className='svgSocial1'/></p></a>
    <a hrefLang="chart" rel="opener" href="https://www.linkedin.com/company/zaktech-ch/"> <p><AiFillLinkedin className='svgSocial1'/></p></a>
    </div>

 <div className='futerButton'>
    <NavLink to="/contact"><button className='bootonBaner'><span>Contact us</span></button></NavLink>      
               </div>
 </div>

</div>
 


 <div className='futerPrivacy'>
 <div className='zacTechPolice'>
     <h6>© ZAK TECH 2024</h6>

  
  <NavLink to="/generalConditions"><p> | General Conditions</p></NavLink>
  <NavLink to="/privacyPolicy"> <p> | Privacy Policy</p></NavLink>
  <NavLink to="/cookiePolicy"> <p> | Cookie Policy</p></NavLink>
 </div>
 
 
 </div>
   </section>
     
       </>

)}